
import * as Components from "./Tenramen"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "tenramen"
}

