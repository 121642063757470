import React, {useState} from 'react'
import {injectShopContainer} from '../react/ShopDataContainer'
import CartContainer, {injectCartContainer} from '../react/CartContainer'
import {Link} from 'react-router-dom';



function PromoCode ({cart, shop}) {
  const {discount} = cart.getSubtotals()
  function promoCodeChanged (e) {
    const code = String(e.target.value || '')
    cart.setPromoCode(code)
  }

  if (['kokorollpa','kluckinsd','lancers','cravestudiocity','pablitostacosnoho', 'pablitospizza', 'tanghuo', 'wokbar', 'pablitostacosburbank','pablitostacosoaks', 'seorae', 'themelt', 'mikaza', 'beachwood', 'inthemix', 'cravesunset'].indexOf(shop.shopId) === -1) { return null}
  const isBeachwood = ['beachwood'].indexOf(shop.shopId) !== -1
  return(
    <div className="promo-code">
      <label htmlFor="promo-code-input">Promo/Gift Code:</label>
      <input
        value={cart.state.promoCode}
        onChange={promoCodeChanged}
        id="promo-code-input" type="text"/>
      {(discount > 0.5) ? <div className="applied">discount: ${discount}</div> : null}
      {(isBeachwood && (['llama','bestpizza'].indexOf((cart.state.promoCode || '').toLowerCase()) !== -1) ) ?  <div 
      >
        <Link className="hidden-link" to="/products/PN262LCu1vnDbiY2cNQ1">🍕Add Free Pizza🍕</Link>
      </div> : null  }

    </div>
  )
}

const injectedPromoCode = injectShopContainer(injectCartContainer(PromoCode))

export default injectedPromoCode
